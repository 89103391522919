<template>
	<div class="dashboard">
		<Breadcrumbs :items="crumbs" />
    <div class="dashboard__main">
      <div class="dashboard__main--header">
        <h1 class="ml-3 mt-3">New Order</h1>
        <router-link :to="{ name: 'custOrders' }">
          <button class="btn"><i class="fad fa-arrow-left fa-2x"></i></button>
        </router-link>
      </div>
      <hr>
      <div class="flex flex-wrap">
        <div class="flex__col1">
        	<div class="card">
	        	<form enctype="multipart/form-data" @submit.prevent="placeOrder">
	        		<h3>Upload Purchase Order</h3>
	        		<label for="po">Select File:</label>
	        		<div class="flex justify-space-between align-center">
						  	<input type="file" style="width:70%; margin-bottom: 0;" id="po" name="po" accept=".pdf,.doc" class="no-border" @change="uploadFile">
						  	<transition name="fade">
							  	<span style="color:green;" v-if="order.po">
							  		<i class="fad fa-check fa-2x"></i>
							  	</span>
							  </transition>
							</div>
						  <transition name="fade">
                <Loader v-if="performingRequest" />
              </transition>
              <div class="mt-3">
                <label for="line">Your Purchase Order Number</label>
                <input type="text" placeholder="123456" v-model.trim="order.poNumber" id="line" required />
              </div>
              <div>
                <label for="details">Any extra details we should know:</label>
                <textarea placeholder="N95, Non-Vented..." id="details" cols="30" rows="4" v-model.trim="order.details"></textarea>
              </div>
              <button :disabled="(!order.po || !order.poNumber)" class="btn btn__primary mt-4 mb-3 mr-4" type="submit">
                Submit
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest2">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
	        	</form>
        	</div>
        </div>
        <div class="flex__col2">
        	<transition name="fade">
        		<Loader v-if="!currentUser" />
        	</transition>
        	<div class="card mb-3" v-if="customerProfile">
        		<h4>Your Account Information</h4>
        		<hr>
        		<p><strong>{{customerProfile.entity}}</strong></p>
        		<p>{{customerProfile.firstName}} {{customerProfile.lastName}}</p>
        		<p>Email: {{customerProfile.email}}</p>
        		<p>Phone: {{customerProfile.phone}}</p>
        	</div>
        	<div class="card mb-3">
        		<p>*All orders are subject to Medea Medical Products’ written acceptance.</p>
        	</div>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
const fb = require('../../../firebaseConfig.js')

export default {
  name: 'newOrder',
  data() {
    return {
    	performingRequest:false,
    	performingRequest2: false,
    	order: {}
  	}
  },
  components: {
  	Loader,
    Breadcrumbs
  },
  computed: {
    ...mapState(['currentUser', 'customerProfile']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Dashboard", to: { name: "dashHome"}}
      let step2 = { title: "My Orders", to: { name: "custOrders"}}
      let step3 = { title: 'New Order', to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      crumbsArray.push(step3)
      return crumbsArray
    },
  },
  methods: {
   	uploadFile(event) {
   		console.log(event.target.files)
   		let file = event.target.files[0];
   		let order = this.order
      this.performingRequest = true
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let fileRef = fb.storageRef.child('POs/' + rand)
      fileRef.put(file).then((snap) => {
         fileRef.getDownloadURL().then(function(downloadURL) {
          console.log('File available at', downloadURL)
          order.po = downloadURL
        })
      })
      setTimeout(() => {
        this.performingRequest = false
      }, 3000)
    },
    placeOrder() {
    	this.performingRequest2 = true
      let order = this.order;
      let customer = this.customerProfile
      setTimeout(() => {
        this.$store.dispatch('newOrder', {order, customer})
        this.performingRequest2 = false
        this.order = {}
        this.$router.push('/dashboard/orders')
      }, 3000)
    }
	}
}
</script>